.input-group-middle {
    border-radius: 0;
}
.input-group.error-group > .form-control {
    border: none;
}
.input-group.error-group > .input-group-text {
    visibility: hidden;
}
.btn-outline-primary:hover {
    background-color: #38a8e0 !important;
    border-color: #38a8e0 !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: #38a8e0 !important;
    border-color: #38a8e0 !important;
}
.page-item.active .page-link {
    background-color: #38a8e0;
    border-color: #38a8e0;
}
/*
.daterange input[type="text"].form-control.datepicker {
    width: 110px;
}*/