/* @import url('//fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&subset=latin-ext');*/
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:400,400i,700,700i&subset=latin-ext');

body {
    min-width: 1200px;
    position: relative;
}

.layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    z-index: 900;
    
}
.layout-header {
    background: linear-gradient(to right, #5abeb9 0%,#38a8e0 100%);
}
.layout-header .container-fluid > div{
    display: inline-block;
}
.layout-header .container-fluid .header-user {
    float: right;
}
.layout-header .container-fluid .header-user a:hover {
    text-decoration: none;
}
.layout-header .btn {
    min-width: 120px;
}
.header-logo {
    width: 400px;
}
.btn-header-size {
    width: 120px;
}


.layout-footer {
}
.layout-middle {
    display: flex;
    flex-grow: 1;
    min-height: 0;
}
.layout-content {
    flex-grow: 1;
    overflow: auto;
}
.layout-left {
    overflow-y: auto;
    /* transition: flex 0.3s ease-out; */
    position: relative;
    flex: 0 0 360px;
    background-color: #f0f2f4;    
    display: flex; 
    flex-direction: column; 
    padding: 0 15px;    
}
.layout-left .user-filters-wrapper {
    overflow: hidden; 
    min-height: 0; 
    flex-grow: 1; 
    display: flex;
    margin-bottom: 15px;
}
.layout-left .user-filters-wrapper .user-filters-block {
    overflow: auto;
    transition: height 0.2s;
    height: 0;
}
.layout-left .form-group label { 
    width: 25%;
    font-size: 0.8em;
}

.layout-left .form-group .dropdown.bootstrap-select.form-control {
    width: 73%;
}

.layout-left .form-group .form-control {
    display: inline-block;
    width: 73%;
}

.layout-left .form-group.daterange {
    display: flex;
}

.layout-left .form-group.daterange .input-group {
    width: 73%;
}
.layout-left .form-group.daterange .input-group input{
    text-transform: uppercase;
}

.layout-left .form-group .form-control.btn {
    width: 100%;
    border-width: 1px;
}
.layout-left .form-group label.form-control.btn {
    font-size: 1rem;
}

.layout-left .form-group .label-left[class*="icheck-"] > input:first-child + label::before,
.layout-left .form-group .label-left[class*="icheck-"] > input:first-child + input[type="hidden"] + label::before {
    right: -29px;   
}

.layout-left .form-group .label-left[class*="icheck-"] > input:first-child:checked + label::after,
.layout-left .form-group .label-left[class*="icheck-"] > input:first-child:checked + input[type="hidden"] + label::after {
    right: -29px;
}

.layout-left .form-group .form-control {
    border-width: 0;
}


.layout-left .form-group .input-group > .form-control,
.layout-left .form-group .input-group > .custom-select,
.layout-left .form-group .input-group > .custom-file {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}
.layout-left .form-group .input-group > .input-group-middle {
    border-width: 0;
}

.predefined-menu a {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	background-color: #c2ede3;
	border-radius: 0.25rem;
	color: #212529;
        /* text-transform: uppercase; */
}

.predefined-menu a:hover {
	text-decoration: none;
	background-color: #5abeb9;
}

.predefined-menu a.active {
	background-color: #5abeb9;
}



.trix-color {
    display: inline-block;
    height: 20px;
    width: 20px;
}
.trix-black {
    background-color: #000;
}
.trix-red {
    background-color: #FF0000;
}
.trix-green {
    background-color: #00FF00;
}
.trix-blue {
    background-color: #0000FF;
}
.trix-color-red {
    color: #CB0000;
}
.trix-color-green {
    color: #01876c;
}
.trix-color-blue {
    color: #90AFC5;
}
trix-editor figure.attachment {
    padding: 10px;
    margin: 0;
}

.message-attachment {
    text-align: center;
    padding: 10px;
}
.message-attachment a,
trix-editor .figure-inner {
        border: 10px solid #E4E7EB;
	margin-bottom: 5px;
}
.message-attachment a{
    display: inline-block;
    background: #FFFFFF;
}
.message-attachment.attachment-file a,
trix-editor figure.attachment--file .figure-inner {
    width: 84px;
    height: 100px;
    background-size: contain;
    background-image: url(/img/other.png);
}
.message-attachment.attachment-file.file-pdf a,
trix-editor figure.attachment--file.attachment--pdf .figure-inner {
    background-image: url(/img/pdf.png);
}
.message-attachment.attachment-file.file-csv a,
trix-editor figure.attachment--file.attachment--csv .figure-inner {
    background-image: url(/img/csv.png);
}
.message-attachment.attachment-file.file-txt a,
trix-editor figure.attachment--file.attachment--txt .figure-inner {
    background-image: url(/img/txt.png);
}
.message-attachment.attachment-file.file-zip a,
trix-editor figure.attachment--file.attachment--zip .figure-inner {
    background-image: url(/img/zip.png);
}
.message-attachment.attachment-file.file-rar a,
trix-editor figure.attachment--file.attachment--rar .figure-inner {
    background-image: url(/img/rar.png);
}
.message-attachment.attachment-file.file-doc a,
.message-attachment.attachment-file.file-docx a,
.message-attachment.attachment-file.file-odt a,
trix-editor figure.attachment--file.attachment--doc .figure-inner,
trix-editor figure.attachment--file.attachment--docx .figure-inner,
trix-editor figure.attachment--file.attachment--odt .figure-inner
{
    background-image: url(/img/doc.png);
}
.message-attachment.attachment-file.file-xls a,
.message-attachment.attachment-file.file-xlsx a,
.message-attachment.attachment-file.file-ods a,
trix-editor figure.attachment--file.attachment--xls .figure-inner,
trix-editor figure.attachment--file.attachment--xlsx .figure-inner,
trix-editor figure.attachment--file.attachment--ods .figure-inner
{
    background-image: url(/img/xls.png);
}
trix-toolbar {
    /* overflow-x: hidden; !!! NELZE kvuli dropdownu s barvama*/
}

.edit-editor trix-editor .attachment[data-trix-mutable="true"] {
	box-shadow: 0 0 0 2px #E4E7EB;
}
.edit-editor trix-editor .attachment__remove--icon {

	border: 2px solid #E4E7EB;
}

.dataTable tbody tr:hover {
    background-color: #E4E7EB;
    cursor: pointer;
}
.table.dataTable td {
    border-top:0;
}
.filter-title {
    	background: #f0f2f4;
	padding: 10px 15px;
}
.filter-title h4 {
    margin: 0;
}
table.dataTable.nowrap th, 
table.dataTable.nowrap td {
	white-space: nowrap;
	max-width: 300px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.dataTables_scroll {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.dataTables_scrollBody {
    border-bottom: 1px solid #DEE2E6;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before, 
table.dataTable thead .sorting_asc_disabled:before, 
table.dataTable thead .sorting_desc_disabled:before {
    font-size: 1rem;
}

.dataTables_filter input.form-control {
    height: 38px;
}

.edit-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.edit-content {
    display: flex;
    flex-grow: 1;
    flex-direction: row;        
    min-height: 0;
    height: 0; /* IE fix */
}
.edit-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
}
.edit-main > div{
    padding: 0.5rem 15px;
}
.edit-main .edit-messages {
    padding-left: 0;
    padding-right: 0;
    flex-grow: 1;
    /*flex: 6;*/
    overflow-y: auto;
}
.edit-main .edit-messages-container {
	flex-grow: 1;
	overflow-y: auto;
	padding: 0;
	display: flex;
}
.edit-timeline {
	/*flex: 1;*/
        flex: 0 0 210px;
	padding: 0 0 0 30px;
        overflow-y: auto;
        min-height: 0;
}
.edit-timeline ul li .itm.itm-note {
	font-size: 12px;
}
.edit-timeline ul li .itm.itm-note span {
	display: none;
}
.edit-title-group {
    display: flex;
}
.ticket-files li.list-group-item div.filename{
    overflow-x: hidden;
    margin-right: 0.5rem;
    text-overflow: ellipsis;
    position: relative;
}
.ticket-files li.list-group-item .badge{
    margin-left: 0.2rem;
    cursor: pointer;
}
.ticket-files li.list-group-item .badge i.mi{
    font-size: 16px;
}
.edit-layout.edit-mode .edit-buttons {
    background: #c2ede3;
    /* margin-left: -15px;    */
}
.edit-buttons {
    flex: 0 0 70px;
}
.edit-layout.edit-mode .edit-main div.edit-editor {
    background: #c2ede3;
    padding-left: 15px;
    /* margin-left: -15px; */
}
.edit-main div.edit-editor.new {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.edit-main div.edit-editor.new trix-editor{
    max-height: none;
    flex-grow: 1;
}

.edit-layout.edit-mode .edit-main div.edit-editor trix-editor {
    background: #ffffff;
    border: 0;
}
.edit-main div.edit-editor trix-editor {
    max-height: 30vh; 
    overflow: auto;
}
.ticket-upload-button {
    overflow: hidden;
    margin-top: 0.5rem;
}
.ticket-upload-button input[type="file"]{
    cursor: pointer;
}
.ticket-upload-button label{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.attachment-list .list-group-item {
	border: 0;
	margin: 0 0 2px 0;
	padding: 0.75rem;
	font-size: 0.9rem;
        line-height: 1.3;
        position: relative;
}
.attachment-list .mi {
	color: #ffffff;
	background: #abb0b5;
	padding: 2px;
	border-radius: 0.25rem;
	font-size: 24px;
}
.attachment-list .mi.delete {
	background: #da001f;
}
.attachment-list a:hover .mi.delete {
	background: #878d93;
}

.attachment-list a:hover .mi {
	background: #076fb8;
        color: #FFF;	
}

.attachment-list a {
	margin-left: 0.25rem;
}

.bg-mis {
    background-color: #f8f9fa;
}
.bg-client {
    background-color: #ecfafb;
}
.bg-note {
    background-color: #fdf9ef;
}
.col.col-conversation-left {
    flex: 0 0 170px;
}
.card.conversation {
    min-width: 430px;
    margin-bottom: 0.5rem !important;
}
.card.conversation .card-body {
    font-size: 0.9rem;
    padding: 1rem;
}

.trix-button-row .dropdown-menu,
.trix-button-row .bootstrap-select .dropdown-menu {
    min-width: inherit;
}
.trix-button-row .dropdown-item {
    width: auto;
}
.dataTables_wrapper .dataTables_length {
    padding-top: 0.64em;
}
.dataTables_wrapper .dataTables_info {
    text-align: center;
}


.btn-primary.btn-header-size {
	background-color: #38a8e0;
	color: #FFFFFF;
	border-color: #38a8e0;
}

.btn-primary.btn-header-size:hover {
	background-color: #ffffff;
	color: #076fb8;
	border-color: #076fb8;
}

.filters-openner {
	background-color: #f8f9fa;
	border-radius: 0.25rem;
	padding: 0.75rem 1.25rem;
	display: flex;
	color: #076fb8;
	text-transform: uppercase;
	position: relative;
        cursor: pointer;
}
.openner-text {
	padding-left: 10px;
}
.mi.icon-after {
	position: absolute;
	right: 16px;
}

.filter-title .mi {
	color: #abb0b5;
	margin-left: 0.25rem;
}
.filter-title a:hover .mi {
	color: #da001f;
}
.filter-active-delimiter {
	color: #d3d3d3;
}

/* LOGIN */
/*
.login-screen {
    width: 100vw;
    height: 100vh;
    background: #f0f2f4;
    padding: 70px 0 0 0;
}*/

.login-screen {
    background-image: url(/img/header.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    height: 100vh;
    padding: 60px 0 0 0;
}

.login-window {
    width: 400px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.4);
    margin: 0 auto;    
}
.logo {
    width: 400px;
    height: auto;
    margin: 0 auto 15px auto;
    padding: 15px 0;
/*    background: #203389; */
    text-align: center;
}
.ekko-lightbox .modal-dialog {
  flex: 100%!important;
  margin-left: 0;
  margin-right: 0;
}
.data-table-100.table {
    min-width: 100%;
    max-width: 100% !important;
    font-size: 0.9rem;
    border-spacing: 0 2px;
}
.data-table-100 tr td:first-child {
	border-left: 5px solid silver;
}
.data-table-100 tr.ticket-prio-1 td:first-child {
	border-left: 5px solid #01876c;
}
.data-table-100 tr.ticket-prio-2 td:first-child {
	border-left: 5px solid orange;
}
.data-table-100 tr.ticket-prio-3 td:first-child {
	border-left: 5px solid red;
}
.data-table-100.table th{
    font-size: 0.8rem;
}
.edit-editor trix-editor a {
	text-decoration: underline;
}
.edit-editor trix-editor h5 {
	font-weight: bold;
}

.edit-editor trix-editor a:hover {
	text-decoration: underline;
}

.edit-editor trix-editor blockquote {
	margin: 0 0 0 0.3em;
	padding: 0 0 0 0.6em;
	border-left: 0.3em solid #ccc;
}

.edit-editor trix-editor pre {
	display: inline-block;
	width: 100%;
	vertical-align: top;
	/*font-family: monospace;*/
	font-size: 0.85em;
	margin: 0;
	padding: 0.5em;
	white-space: pre;
	background-color: #eee;
	overflow-x: auto;
}
.control-ro {
	width: 73%;
	display: inline-block;
	background-color: #f8f9fa;
	border-radius: 0.25rem;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
	color: #999;
}

.form-group.form-group-grouped {
position: relative; 
}
.layout-left .form-group.field-mandays .form-control {
	width: 85px;
}
.form-group.field-invoiced {
	position: absolute;
	top: 3px;
	right: 0;
}

.ekko-lightbox .modal-header h4 {
    font-size: 0.8rem;
}
.ekko-lightbox .modal-header .close {
	line-height: 0.8;
}

.file-progress {
    position: absolute;
    width: 0;
    height: 100%;
    background: #faf5e8;
    margin-left: -0.75rem;
}

.edit-timeline ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 0.9rem;
}
.edit-timeline ul li {
    position: relative;
    padding-left: 25px;
}
.edit-timeline ul li:before {
    content: "";
    width: 1px;
    height: 100%;
    border-left: 1px solid #ddd;
    position: absolute;
    left: 0;
    top: 0;
}
.edit-timeline ul li.timeline-first:before {
    height: 50%;
    top: 50%;
}
.edit-timeline ul li.timeline-last:before {
    height: 50%;
}
.edit-timeline ul li .itm {
    text-decoration: none;
    line-height: 2.5;
    color: #777777;
    display: block;
    position: relative;
    cursor: pointer;
}
.edit-timeline ul li .itm.active,
.edit-timeline ul li .itm:hover {
    color: #076fb8;
}
.edit-timeline ul li .itm:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid #dddddd;
    background: #ffffff;
    border-radius: 8px;
    left: -32px;
    top: calc(50% - 8px);
}
.edit-timeline ul li .itm:hover:before {
    border-color: #076fb8;
}
.edit-timeline ul li .itm.active:before {
    border-color:  #076fb8;
    background: #c2ede3;
}
.edit-timeline ul li .itm span {
    font-size: 0.65rem;
    color: #777;
    padding: 0 3px;
    background: #ecfafb;
    margin-left: 5px;
    border-radius: 3px;
    cursor: pointer;
    min-width: 30px;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
}
.edit-timeline ul li.timeline-first.timeline-last::before {
	border: none;
}

.attachment__caption {
	font-size: 0.8rem;
        margin:0;
}

.left-title {
    padding: 10px 30px;
    border-bottom: 1px solid #ffffff;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}
.left-title h4 {
    margin: 0;
}
.left-title .mi {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 32px;
}
.mi.opener {
	position: absolute;
	right: 12px;
	top: 12px;
	display: none;
	font-size: 28px;
	color: #a5b1bd;
        cursor: pointer;
}
.mi.opener:hover {
    color: #076fb8;
}
.left-opened .mi.opener.left-close {
	display: block;
        transform: rotate(90deg);
}
.left-closed .mi.opener.left-open {
	display: block;
        transform: rotate(-90deg);
}
.layout-left.left-closed {
	flex: 0 0 48px;
}
.layout-left.left-closed div {
	position: absolute;
	left: -360px;
}

.alerts-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: none;
}

.alerts-container .alert {
    padding: 1.75rem 1.5rem;
    max-width: 400px;
    margin: 5% auto 0 auto;
}

.alerts-container .alert-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #555555;
}

.alerts-container .alert-close:hover {
    color: #da001f;
}
.no-mb {
    margin-bottom: 0 !important;
}
.alert-block {
    /*background: #fcb4b7;*/
    margin: 0 15px 15px 15px;
    /* padding: 15px 20px 5px 20px; */
    text-align: center;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #212529;
}


.bootstrap-select > .dropdown-toggle.btn.disabled.bs-placeholder,
.bootstrap-select > .dropdown-toggle.btn.disabled.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.btn.disabled.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.btn.disabled.bs-placeholder:active {
    opacity: 0.4;
}
.field-mdconfirmed {
    position: absolute;
    right: 115px;
    top: 7px;
}

.mdOk {
color: #01876c;
}

.mdNo {
color: #f1b54c;
}

.td-no-padding td{
    padding:0;
}
.td-no-padding td a.list-cell{
    padding: 0.75rem;
    display: inline-block;
    color: #212529;
    width: 100%;
    height: 100%;
}

.td-no-padding td a.list-cell:hover{
    text-decoration: none;
}

.bg-mis-green1 {
    background: #5abeb9;
}

.gdpr-box {
    max-width: 1100px;
    margin: 0 auto 30px auto;
}
/*
.gdpr-text {
    max-height: calc(100vh - 280px);
    overflow: auto;
    padding-right: 15px;
}
*/