.btn-group > .bootstrap-select > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) >  .bootstrap-select > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group > .bootstrap-select > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .bootstrap-select > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.bootstrap-select.icon-select .dropdown-toggle:before {
  content: none;
}
.bootstrap-select.icon-select .dropdown-toggle {
    padding-right: 1.5rem;
}
.bootstrap-select.icon-select .dropdown-toggle::after {
    position: absolute;
    top: 50%;
}
.bootstrap-select.icon-select .dropdown-menu li a span.text {
    display: block;
    line-height: 1;
}
.bootstrap-select.icon-select .dropdown-menu li a span.text i.mi{
    display: block;
}
.bs-container.dropdown.bootstrap-select.show.position-static {
    position: absolute !important;
}
