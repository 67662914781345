@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins/buttons";

$datepicker-color: $info;
$btn-info-bg: $info;

$gray-light: $gray-200;
$gray-lighter: $gray-100;

.datepicker {
  /* padding: 4px; */

  @include border-radius($input-border-radius);

  &-inline {
    width: 220px;
  }

  direction: ltr;

  &-rtl {
    direction: rtl;

    table tr td span {
      float: right;
    }
  }

  &-dropdown {
    top: 0;
    left: 0;

    &:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $gray-light;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }

    &:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $white;
      border-top: 0;
      position: absolute;
    }

    &.datepicker-orient-left:before {
      left: 6px;
    }

    &.datepicker-orient-left:after {
      left: 7px;
    }

    &.datepicker-orient-right:before {
      right: 6px;
    }

    &.datepicker-orient-right:after {
      right: 7px;
    }

    &.datepicker-orient-bottom:before {
      top: -7px;
    }

    &.datepicker-orient-bottom:after {
      top: -6px;
    }

    &.datepicker-orient-top:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid $gray-light;
    }

    &.datepicker-orient-top:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid $white;
    }
  }

  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td, th {
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;

    @include border-radius($input-border-radius);

    border: none;
  }

  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color: transparent;
    }
  }

  table tr td {
    &.day:hover,
    &.day.focused {
      background: $gray-lighter;
      cursor: pointer;
    }

    &.old,
    &.new {
      color: $gray-light;
    }

    &.disabled,
    &.disabled:hover {
      background: none;
      color: $gray-light;
      cursor: default;
    }

    &.highlighted {
      background: $btn-info-bg;
      border-radius: 0;
    }

    &.today,
    &.today:hover,
    &.today.disabled,
    &.today.disabled:hover {
      $datepicker-today-bg: lighten($orange, 30%);

      @include button-variant($warning, $warning);

      color: #000;
    }

    &.today:hover:hover {
      // Thank bootstrap 2.0 for this selector...
      // TODO: Bump min BS to 2.1, use @textColor in buttonBackground above
      color: #000;
    }

    &.today.active:hover {
      color: #fff;
    }

    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
      background: $gray-lighter;

      @include border-radius($input-border-radius);
    }

    &.range.today,
    &.range.today:hover,
    &.range.today.disabled,
    &.range.today.disabled:hover {
      $datepicker-range-today-bg: mix($orange, $gray-lighter, 50%);

      @include button-variant($warning, $warning);
      @include border-radius($input-border-radius);
    }

    &.selected,
    &.selected:hover,
    &.selected.disabled,
    &.selected.disabled:hover {
      @include button-variant(lighten($gray-light, 10), darken($gray-light, 10), darken($gray-light, 20));

      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    &.active,
    &.active:hover,
    &.active.disabled,
    &.active.disabled:hover {
      @include button-variant($datepicker-color, $datepicker-color);

      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;

      @include border-radius($input-border-radius);

      &:hover,
      &.focused {
        background: $gray-lighter;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $gray-light;
        cursor: default;
      }

      &.active,
      &.active:hover,
      &.active.disabled,
      &.active.disabled:hover {
        @include button-variant($datepicker-color, $datepicker-color);

        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &.old,
      &.new {
        color: $gray-light;
      }
    }
  }

  .datepicker-switch {
    width: 145px;
  }

  .datepicker-switch,
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;

    &:hover {
      background: $gray-lighter;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}

.input-append,
.input-prepend {
  &.date .add-on {
    cursor: pointer;

    i {
      margin-top: 3px;
    }
  }
}

.input-daterange {
  input {
    text-align: center;
  }

  input:first-child {
    @include border-left-radius($input-border-radius);
  }

  input:last-child {
    @include border-right-radius($input-border-radius);
  }

  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: $line-height-base;
    padding: 4px 5px;
    font-weight: normal;
    line-height: $line-height-base;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    vertical-align: middle;
    background-color: $gray-lighter;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }
}
