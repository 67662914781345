/*!
 * icheck-bootstrap v3.0.1 (https://github.com/bantikyan/icheck-bootstrap)
 * Copyright 2018 Hovhannes Bantikyan.
 * Licensed under MIT (https://github.com/bantikyan/icheck-bootstrap/blob/master/LICENSE)
 */

@import "../bootstrap/functions";
@import "../bootstrap/variables";

 [class*="icheck-"] {
    min-height: 22px;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding-left: 0px;
}

.icheck-inline {
    display: inline-block;
}

    .icheck-inline + .icheck-inline {
        margin-left: .75rem;
        margin-top: 6px;
    }

[class*="icheck-"] > label {
    min-height: 22px;
    line-height: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    padding-left: 29px !important;
}
[class*="icheck-"].label-left > label {
    padding-left: 0 !important;
    padding-right: 29px !important;
}

[class*="icheck-"] > input:first-child {
    position: absolute !important;
    opacity: 0;
    margin: 0;
}

    [class*="icheck-"] > input:first-child:disabled {
        cursor: default;
    }

    [class*="icheck-"] > input:first-child + label::before,
    [class*="icheck-"] > input:first-child + input[type="hidden"] + label::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 22px;
        height: 22px;
        border: 1px solid $gray-400;
        border-radius: 0px;
        margin-left: -29px;
    }
    
    [class*="icheck-"].label-left > input:first-child + label::before,
    [class*="icheck-"].label-left > input:first-child + input[type="hidden"] + label::before {
        right: 0;
    }
    [class*="icheck-"].label-left > input:first-child:checked + label::after,
    [class*="icheck-"].label-left > input:first-child:checked + input[type="hidden"] + label::after {
        transform: translate(-7.75px, 4.5px) rotate(45deg);
    }
    
    [class*="icheck-"] > input:first-child:checked + label::after,
    [class*="icheck-"] > input:first-child:checked + input[type="hidden"] + label::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 7px;
        height: 10px;
        border: solid 2px $white;
        border-left: none;
        border-top: none;
        transform: translate(7.75px, 4.5px) rotate(45deg);
        -ms-transform: translate(7.75px, 4.5px) rotate(45deg);
    }
    [class*="icheck-"].label-left > input:first-child:checked + label::after,
    [class*="icheck-"].label-left > input:first-child:checked + input[type="hidden"] + label::after {
        right: 0;
        left: auto;
    }
    
    [class*="icheck-"] > input[type="radio"]:first-child:checked + label::after,
    [class*="icheck-"] > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
        top: 6px;
        left: 6px;
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        transform: none;
        -ms-transform: none;
        background: $white;
    }

[class*="icheck-"] > input[type="radio"]:first-child + label::before,
[class*="icheck-"] > input[type="radio"]:first-child + input[type="hidden"] + label::before {
    border-radius: 50%;
}

[class*="icheck-"] > input:first-child:not(:checked):not(:disabled):hover + label::before,
[class*="icheck-"] > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-width: 2px;
}

[class*="icheck-"] > input:first-child:disabled + label,
[class*="icheck-"] > input:first-child:disabled + input[type="hidden"] + label,
[class*="icheck-"] > input:first-child:disabled + label::before,
[class*="icheck-"] > input:first-child:disabled + input[type="hidden"] + label::before {
    pointer-events: none;
    cursor: default;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.icheck-default > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-default > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $secondary;
}

.icheck-default > input:first-child:checked + label::before,
.icheck-default > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $secondary;
    border-color: $secondary;
}

/*
.icheck-default > input:first-child:checked + label::after,
.icheck-default > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: $gray-800;
    border-right-color: $gray-800;
}
.icheck-default > input[type="radio"]:first-child:checked + label::after,
.icheck-default > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
    background: $gray-800;
}*/

.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $primary;
}

.icheck-primary > input:first-child:checked + label::before,
.icheck-primary > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $primary;
    border-color: $primary;
}

.icheck-success > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-success > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $success;
}

.icheck-success > input:first-child:checked + label::before,
.icheck-success > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $success;
    border-color: $success;
}

.icheck-info > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-info > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $info;
}

.icheck-info > input:first-child:checked + label::before,
.icheck-info > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $info;
    border-color: $info;
}

.icheck-warning > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-warning > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $warning;
}

.icheck-warning > input:first-child:checked + label::before,
.icheck-warning > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $warning;
    border-color: $warning;
}

.icheck-danger > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-danger > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: $danger;
}

.icheck-danger > input:first-child:checked + label::before,
.icheck-danger > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: $danger;
    border-color: $danger;
}
